import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import { ImMenu3, ImMenu4 } from "react-icons/im";
import Navbar from "./Navbar";
import logo2 from '../../Assests/images/logo-light.png';

const Header = ({ activeHeading }) => {

  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);

    
  window.addEventListener("scroll", () => {
    if (window.scrollY > 70) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  return (
    <>      
      <div
        className={`${
          active === true ? "shadow-sm fixed top-0 left-0 z-10" : null
        } transition hidden 800px:flex items-center justify-between w-full bg-[#012] h-[70px]`}
      >
        <div
          className={`${styles.section} relative ${styles.noramlFlex} justify-between`}
        >

          <div>
            <Link to="/">
              <img
                src={logo2}
                width='60px'
                alt=""
              />
            </Link>
          </div>
          
          {/* navitems */}
          <div className={`${styles.noramlFlex} !font-[300]`}>
            <Navbar active={activeHeading}/>
          </div>

          <div className="flex">                                        
            <div className={`${styles.button} !bg-[#c89b2a]`}>
                <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">
                  Request a quote
                  </h1>
                </Link> 
            </div>
          </div>
        </div>
      </div>

      {/* mobile header */}
      <div
        className={`${
          active === true ? "shadow-sm fixed top-0 left-0 z-10" : null
        }
      w-full h-[60px] bg-[#fff] z-50 top-0 left-0 shadow-sm 800px:hidden`}
      >
        <div className="w-full flex items-center justify-between">
          <div>
            <ImMenu3
              size={35}
              className="ml-4"
              onClick={() => setOpen(true)}
            />
          </div>
           <div>
            <Link to="/">
              <img
                src={logo2}
                alt=""
                width='45px'
                className="mt-2 cursor-pointer"
              />
            </Link>
          </div>
        </div>

        {/* header sidebar */}
        {open && (
          <div
            className={`fixed w-full bg-[#0000005f] z-20 h-full top-0 left-0`}
          >
            <div className="fixed w-[70%] bg-[#fff] h-screen top-0 left-0 z-10 overflow-y-scroll">
              <div className="w-full justify-between flex pr-3">
                <div>
                 </div>
                <ImMenu4
                  size={30}
                  className="ml-4 mt-5"
                  onClick={() => setOpen(false)}
                />
              </div>

             

              <Navbar active={activeHeading}/>
              <div className={`${styles.button} ml-4 !rounded-[4px]`}>
               <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">
                  Request a quote
                  </h1>
                </Link> 
              </div>
             <br />
              <br />
              <br />

            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;

