import React from 'react';
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

function Hero3() {
   
  return (
    <div className='w-full'>

    <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>
      <div className={`${styles.section} w-full`}>
      <h1
          className={`!text-[45px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
         OCEAN HAWK OIL RESOURCES LTD. <span className='text-[16px]'>RC-473600</span>
        </h1>

        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        Ocean Hawk Oil Resources Ltd is an Oil & Gas marketing and haulage company based in Port Harcort Nigeria.
        We are general suppliers of all types of oil field production chemicals, rig washes and degreasers.
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        We also supply petrol (PMS), diesel (AGO), kerosene (DPK), low pour fuel oil (LPFO) and oil field drilling rigs,
        550HP, 750HP, 1000HP, 1500HP, 2000HP, AC/DC, Skid Mounted, Truck Mounted, Desert Fast Moving, Drilling tools, Addictives and Fluids.
        </p>
      </div>
    </div>
    <br />
   

      

      <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>
        <div className={`${styles.section} w-full 800px:w-[50%]`}>
        <h1
          className={`!text-[25px] leading-[0.9] 800px:text-[80px] text-[#fff] px-4 py-2 font-[600] bg-[#c89b2a] rounded-xl w-[300px]`}
        >
         OUR VISION
        </h1>
        <p className="pt-2 text-[16px] font-[Poppins] text-[#000000ba]">
        To become a global producer and supplier of petroleum products, oil production tools and oil field chemicals in Nigeria and beyond. 
       </p>
        <br />
        </div>

        <div className={`${styles.section} 800px:ml-4 w-full 800px:w-[50%]`}>
        <h1
          className={`!text-[25px] leading-[0.9] 800px:text-[80px] text-[#fff] px-4 py-2 font-[600] bg-[#012] rounded-xl w-[300px]`}
        >
         MISSION STATEMENT
        </h1>
        <p className="pt-2 text-[16px] font-[Poppins] text-[#000000ba]">
        Our focus is on delivering excellent products and services to our customers without compromising our commitment to best 
        business practices health and safety matters and the promotion of a clean environment. 
       </p>
        <br />
        
        </div>
        </div>


        <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>

        <div className={`${styles.section} 800px:ml-4 w-full 800px:w-[50%]`}>
        <h1
          className="!text-[40px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]"
        >
          OUR PRODUCTS<br /> & SERVICES
        </h1>
        <h4 className="pt-3 text-[25px] font-[Poppins] font-[500] text-[#000000ba]">
          CHEMICALS:
        </h4>

        <br />
        <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1EeVYN2Bh-xUU34ZXBKBEOq26v0yFMopBTw&s"
            alt=""
            className="w-[90%] p-3"
          />
        
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
        Supply of Oil Feld Production Chemicals and Degreasers. Our range of chemicals: 
        </p>
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
        * <span className="font-[500] mb-3">Drilling and Completions</span> Fluid Additives <br />
        * <span className="font-[500] mb-3">Production</span> Chemicals <br />
        * <span className="font-[500] mb-3">Refinery and Process</span> Chemicals <br />
        * <span className="font-[500] mb-3">Hydro Testing</span> Chemicals <br />
        * <span className="font-[500] mb-3">Water Treatment</span> Chemicals <br />
        * <span className="font-[500] mb-3">Waste Oil Treatment</span> Chemicals <br />
        * <span className="font-[500] mb-3">Stimulation and Cementing</span> Chemicals. <br />
        </p>

        <div className={`${styles.button} !bg-[#000] ml-4 !rounded-[4px]`}>
               <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">
                  Get a quote
                  </h1>
                </Link> 
              </div>

        <h4 className="pt-3 text-[25px] font-[Poppins] font-[500] text-[#000000ba]">
          RIGS:
        </h4>
        
        <br />
        <img
            src="https://www.royalhaskoningdhv.com/-/media/images/websites/twinn/impact-stories/sea-vessel-number-optimisation-for-offshore-platforms--h.jpg?h=1080&iar=0&w=1920&hash=C7FD8D23530A489D72F23C3168D8EA07"
            alt=""
            className="w-[90%] p-3"
          />


      <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
      We supply all types of oil field rigs including:
      </p>
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
        * <span className="font-[500] mb-3">Skid Mounted</span> Drilling Rigs <br />
        * <span className="font-[500] mb-3">Jack Up</span> Offshore Rigs <br />
        * <span className="font-[500] mb-3">Truck Mounted</span> Drilling Rigs <br />
        </p>

        <div className={`${styles.button} !bg-[#000] ml-4 !rounded-[4px]`}>
               <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">
                  Get a quote
                  </h1>
                </Link> 
              </div>

        <br />
        </div>


        <div className='w-full 800px:w-[50%]'>

        <h4 className="pt-3 text-[25px] font-[Poppins] font-[500] text-[#000000ba]">
        PETROLEUM PRODUCTS:
        </h4>
        
        <br />
        <img
            src="https://www.vision-techniques.com/wp-content/uploads/2023/09/fuel-tanker@3x.png"
            alt=""
            className="w-[90%] p-3"
          />


      <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
      We supply petroleum products including:
      </p>
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
        * Premium Motor Spirit (PMS) - Petrol  <br />
        * Automotive Gas Oil (AGO) - Diesel <br />
        * Dual Purpose Kerosene (DPK) - Kerosene <br />
        * Low Pour Fuel Oil (LPFO) - Black Oil <br />
        </p>



        <div className={`${styles.button} !bg-[#000] ml-4 !rounded-[4px]`}>
               <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">
                  Get a quote
                  </h1>
                </Link> 
              </div>
              <br />
              <br />

        <h1
          className="!text-[40px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]"
        >
          SUPPLY OF DRILLING AND COMPLETION FLUID ADDITIVES
        </h1>
        
        <br />
        <img
            src="https://7245686.fs1.hubspotusercontent-na1.net/hub/7245686/hubfs/Z3C65197%20(1).jpg?width=575&name=Z3C65197%20(1).jpg"
            alt=""
            className="w-[90%] p-3"
          />

        <p className='pt-3 text-[16px] font-[Poppins] text-[#000000ba]'>
        Improve drilling efficiency, wellbore stability, hole cleaning, lubricity, fluid loss control, and environmental compliance with our quality fluid additives:
        </p>
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
        * <span className="font-[500] mb-3">Calcium</span> Bromide <br />
        * <span className="font-[500] mb-3">Barite</span> & Bentonite <br />
        * <span className="font-[500] mb-3">Calcium</span> Chloride <br />
        * <span className="font-[500] mb-3">Potassium</span> Chloride <br />
        * <span className="font-[500] mb-3">Primary</span> Emulsifier <br />
        * <span className="font-[500] mb-3">Sodium</span> Chloride <br />
        * <span className="font-[500] mb-3">Oil Mud</span> Thinner. <br />
        * <span className="font-[500] mb-3">Oxygen</span> Scavenger <br />
        * <span className="font-[500] mb-3">OXD</span> Polymer <br />
        * <span className="font-[500] mb-3">Secondary</span> Emulsifier <br />
        * <span className="font-[500] mb-3">Non-Ionic</span> Surfactant <br />
        </p>
        
        <div className={`${styles.button} !bg-[#000] ml-4 !rounded-[4px]`}>
               <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">
                  Inquire Now
                  </h1>
                </Link> 
              </div>
              <br />
        <br />
        
        <br />
        </div>        
        
        
        </div>

      </div>
  )
}

export default Hero3
