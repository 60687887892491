import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  HomePage,
  ProductsPage,
  ProductDetailsPage,
  ContactPage,
  PrivacyPolicyPage,
  DrillingandCompPage,
  DrillingandCompPDetailPage,
  DrillingCSingleDPage,
  PhaseSepPage,
  PhaseSepPDetailPage,
  PhaseSepSingleDPage,
  RigsPage,
  RigsPDetailPage,
  RigsSingleDPage,
  FlowAssurancePage,
  FlowAssurancePDetailPage,
  FlowAssuranceSingleDPage,
  DegreaserPage,
  DegreaserPDetailPage,
  DegreaserSingleDPage,
  WaterTChemicalsPage,
  WaterTChemicalsPDetailPage,
  WaterTChemicalsSingleDPage,
  HydroTRCPage,
  HydroTRCPDetailPage,
  HydroTRCSingleDPage,
  PetrolAllPage,
  PetrolAllPDetailPage,
  PetrolAllSingleDPage,
 
  } from "./routes/Routes.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const App = () => {
  
    
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/product/:id" element={<ProductDetailsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

        <Route path="/driling-and-completion-fluid-additives" element={<DrillingandCompPage />} />
        <Route path="/product/driling-and-completion-fluid-additives/:id" element={<DrillingandCompPDetailPage />} />
        <Route path="/driling-and-completion-fluid-additives/:id" element={<DrillingCSingleDPage />} />

        <Route path="/phase-separation" element={<PhaseSepPage />} />
        <Route path="/product/phase-separation/:id" element={<PhaseSepPDetailPage />} />
        <Route path="/phase-separation/:id" element={<PhaseSepSingleDPage />} />

        <Route path="/rigs" element={<RigsPage />} />
        <Route path="/product/rigs/:id" element={<RigsPDetailPage />} />
        <Route path="/rigs/:id" element={<RigsSingleDPage />} />

        <Route path="/flow-assurance" element={<FlowAssurancePage />} />
        <Route path="/product/flow-assurance/:id" element={<FlowAssurancePDetailPage />} />
        <Route path="/flow-assurance/:id" element={<FlowAssuranceSingleDPage />} />

        <Route path="/degreaser-and-disinfectant" element={<DegreaserPage />} />
        <Route path="/product/degreaser-and-disinfectant/:id" element={<DegreaserPDetailPage />} />
        <Route path="/degreaser-and-disinfectant/:id" element={<DegreaserSingleDPage />} />

        <Route path="/water-treatment-chemicals" element={<WaterTChemicalsPage />} />
        <Route path="/product/water-treatment-chemicals/:id" element={<WaterTChemicalsPDetailPage />} />
        <Route path="/water-treatment-chemicals/:id" element={<WaterTChemicalsSingleDPage />} />

        <Route path="/hydro-testing-and-process-chemicals" element={<HydroTRCPage />} />
        <Route path="/product/hydro-testing-and-process-chemicals/:id" element={<HydroTRCPDetailPage />} />
        <Route path="/hydro-testing-and-process-chemicals/:id" element={<HydroTRCSingleDPage />} />

        <Route path="/secondary-fuels" element={<PetrolAllPage />} />
        <Route path="/product/secondary-fuels/:id" element={<PetrolAllPDetailPage />} />
        <Route path="/secondary-fuels/:id" element={<PetrolAllSingleDPage />} />
      </Routes>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
};

export default App;


