import React from 'react';
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

function Hero3b() {
    
  return (
    <div className='w-full'>      

        <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>

        <div className={`${styles.section} 800px:ml-4 w-full 800px:w-[50%]`}>
        <h1
          className="!text-[40px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]"
        >
          SUPPLY OF PHASE SEPARATION PRODUCTS
        </h1>

        <br />
        <img
            src="https://s.alicdn.com/@sc04/kf/H87a4d32244494c0d83ec228c25453db1L.jpg_720x720q50.jpg"
            alt=""
            className="w-[90%] p-3"
          />
        
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
        Enhance crude oil extraction and transportation efficiency by facilitating the separation of oil-water emulsions using our best-selling products: 
        </p>
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
        * <span className="font-[500] mb-3">Demulsifiers</span> and <br />
        * <span className="font-[500] mb-3">Water</span> Clarifiers <br />
        </p>
        <Link to="mailto:info@ohkoil.com" target="_blank">
          <div className={`${styles.button} mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Inquire Now
               </span>
          </div>
      </Link>
        </div>


        <div className='w-full 800px:w-[50%]'>
        <h1
          className="!text-[40px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]"
        >
          SUPPLY OF FLOW ASSURANCE PRODUCTS
        </h1>
        <br />
        <img
            src="https://media.licdn.com/dms/image/D4D12AQFRjxggPgGk-g/article-cover_image-shrink_600_2000/0/1676639785826?e=2147483647&v=beta&t=jjeitt2fBOwxdcJ6Mg4pnEFnAIZ2xHbYstcUhhi9LtQ"
            alt=""
            className="w-[90%] p-3"
          />

        
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">Prevent pipe blockage, ensure uninterrupted and maximized productivity in your oil and gas streams using some of the finest flow assurance products currently in stock: </p>
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
        * <span className="font-[500] mb-3">Corrosion</span> Inhibitors <br />
        * <span className="font-[500] mb-3">Scale</span> Inhibitors <br />
        * <span className="font-[500] mb-3">Demulsifiers</span> <br />
        * <span className="font-[500] mb-3">Anti-Foams</span><br />
        * <span className="font-[500] mb-3">Biocides</span><br />
        * <span className="font-[500] mb-3">Paraffin Dispersants</span> & Inhibitors <br />
        * <span className="font-[500] mb-3">Pour Point</span> Dispersants. <br />
        </p>
        
      <Link to="mailto:info@ohkoil.com" target="_blank">
          <div className={`${styles.button} mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Inquire Now
               </span>
          </div>
      </Link>
        <br />
        <br />
        
        <br />
        </div>        
        
        
        </div>

      </div>
  )
}

export default Hero3b
