import React from "react";
import styles from "../../styles/styles";


const ProductDetails = ({ data }) => {

    
  return (
    <div className="bg-white">
     <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
          <div className="w-full py-5">
           <h1>Loading</h1>
          </div> 
        </div>
     </div>
   
    
  );
};


export default ProductDetails;
