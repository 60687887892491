import React from 'react';
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

function Hero3c() {
    

  return (
    <div className='w-full'>      

        <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>

        <div className={`${styles.section} 800px:ml-4 w-full 800px:w-[50%]`}>
        <h1
          className="!text-[40px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]"
        >
          SUPPLY OF WATER TREATMENT CHEMICALS
        </h1>
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
        OHK Oil deals on quality water treatment chemicals that helps to maintaining the efficiency and integrity of cooling tower systems by preventing scale formation, controlling microbial growth, and minimizing corrosion. Our quality products will help in ensuring the smooth operation of cooling towers and preserve your equipment functionality as well as reducing operational costs in industrial and commercial settings. Products currently in stock includes: 
        </p>
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
        * <span className="font-[500] mb-3">Reversed</span> Demulsifiers <br />
        * <span className="font-[500] mb-3">Flocculants</span> <br />
        * <span className="font-[500] mb-3">Bactericides</span> <br />
        * <span className="font-[500] mb-3">Coagulants</span> <br />
        * <span className="font-[500] mb-3">Sludge</span> Thickeners <br />
        * <span className="font-[500] mb-3">Polyelectrolytes</span> <br />
        * <span className="font-[500] mb-3">Scale</span> Inhibitors <br />
        </p>
        <Link to="mailto:info@ohkoil.com" target="_blank">
          <div className={`${styles.button} mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Get a Quote
               </span>
          </div>
      </Link>

        <br />
        <img
            src="https://chemcape.co.za/wp-content/uploads/2023/10/Company-Profile-53.png"
            alt=""
            className="w-[90%] p-3"
          />
        
        </div>


        <div className='w-full 800px:w-[50%]'>

        <h1
          className="!text-[40px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]"
        >
          SUPPLY OF HYDRO TESTING/REFINERY AND PROCESS CHEMICALS
        </h1>
        <br />
        <img
            src="https://www.lastmar.com/wp-content/uploads/2021/01/boiler-water-Treatment.jpg"
            alt=""
            className="w-[90%] p-3"
          />

        
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
        * <span className="font-[500] mb-3">Corrosion</span> Inhibitors <br />
        * <span className="font-[500] mb-3">Oxygen</span> Scavengers <br />
        * <span className="font-[500] mb-3">Demulsifiers</span> <br />
        * <span className="font-[500] mb-3">Scale</span> Inhibitors <br />
        * <span className="font-[500] mb-3">Biocides</span> <br />
        * <span className="font-[500] mb-3">H2S Scavengers</span><br />
        </p>
        
       <Link to="mailto:info@ohkoil.com" target="_blank">
          <div className={`${styles.button} mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Inquire Now 
               </span>
          </div>
      </Link>
      <br />
        <br />
        
        <br />
        </div>        
        
        
        </div>

      </div>
  )
}

export default Hero3c
