import React from 'react';
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

function Hero3d() {
    

  return (
    <div>  
        <br />    

        <div className={`${styles.section} bg-[#012] p-4 800px:flex`}>

        <div className={`w-full 800px:w-[50%]`}>
        <p className="pt-3 text-[16px] font-[Poppins] text-[#fff]">
        * <Link to="/driling-and-completion-fluid-additives"><span className="mb-3 hover:text-[#c89b2a]">Drilling and Completion Fluid Additives</span></Link> <br />
        * <Link to="/phase-separation"><span className="mb-3 hover:text-[#c89b2a]">Phase Separation</span></Link> <br />
        * <Link to="/rigs"><span className="mb-3 hover:text-[#c89b2a]">Rigs</span></Link> <br />
        * <Link to="/flow-assurance"><span className="mb-3 hover:text-[#c89b2a]">Flow Assurance</span></Link> <br />
       </p>       
                
        </div>

        <div className={`w-full 800px:w-[50%]`}>
        <p className="pt-3 text-[16px] font-[Poppins] text-[#fff]">
        * <Link to="/degreaser-and-disinfectant"><span className="mb-3 hover:text-[#c89b2a]">Chemical Degreasers</span></Link> <br />
        * <Link to="/water-treatment-chemicals"><span className="mb-3 hover:text-[#c89b2a]">Water Treatment Chemicals</span></Link> <br />
        * <Link to="/hydro-testing-and-process-chemicals"><span className="mb-3 hover:text-[#c89b2a]">Hydro Testing and Process Chemical</span></Link> <br />
        * <Link to="/secondary-fuels"><span className="mb-3 hover:text-[#c89b2a]">Secondary Fuels</span></Link> <br />
       </p>       
                
        </div>
               
       </div>
       <br />
      
      </div>
  )
}

export default Hero3d
