import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import DrillingAndComFluidProduct from "../pages/AllProductPages/DrillingAndComFluidProduct";
import PhaseSeparationP from "../pages/AllProductPages/PhaseSeparationP";
import Rigs from "../pages/AllProductPages/Rigs";
import FlowAssurance from "./AllProductPages/FlowAssurance";
import Degreaser from "./AllProductPages/Degreaser";
//import DisAndMultiDetergent from "./AllProductPages/DisAndMultiDetergent";
import WaterTChemicals from "./AllProductPages/WaterTChemicals";
import HydroTRandPChemicals from "./AllProductPages/HydroTRandPChemicals";
import PetrolAll from "./AllProductPages/PetrolAll";
import Hero3d from "../components/Route/Hero/Hero3d";


const ProductsPage = () => {
 
  return (
  <div>
      <Header activeHeading={2} />

      <Hero3d />
      <DrillingAndComFluidProduct />
      <PhaseSeparationP />
      <Rigs />
      <FlowAssurance />
      <Degreaser />
      
      <WaterTChemicals />
      <HydroTRandPChemicals />
      <PetrolAll />

      <Footer />
  </div>
  );
};

export default ProductsPage;
